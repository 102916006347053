import React from "react";
import { contactPriorities, textFields } from "../smeHelpers";
import { Group } from "./Group";

export class NewContactGroup extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        document.title = "Add Contact";
        this.state = {
            workAreas: [],
            areaOfService: []
        }
        const { isToolManager, setState, apiGetWorkAreas, apiGetServiceAreas } = this.props;

        Promise.all([apiGetWorkAreas(), apiGetServiceAreas(), isToolManager()]).then((values) => {
            const [workAreas, areaOfService, isToolManager] = values;

            setState({ isToolManagerBool: isToolManager });

            workAreas.map((area: any) => {
                this.state.workAreas.push({
                    key: area.id,
                    name: area.name
                })
            })

            areaOfService.map((area: any) => {
                this.state.areaOfService.push({
                    key: area.name,
                    name: area.name
                })
            })
        });
    }
    render() {
        return (
            <main id='main' tabIndex={-1}>
                <Group
                    {...this.props}
                    title={"New Contact"}
                    textFields={textFields}
                    workAreas={this.state.workAreas}
                    contactPriorities={contactPriorities}
                    areaOfService={this.state.areaOfService}
                    groupDetails={{workAreaId: process.env.REACT_APP_defaultWorkAreaID, workAreaName: process.env.REACT_APP_defaultWorkAreaName}}
                    CONTACT
                    ADD
                />
            </main>
        );
    }
}
