import React from "react";
import { PersonProps } from "./IProps";
export type ShadowProps = {
    contactList: PersonProps[];
} & any;

const borderWithBoxShadow = { border: '1px solid #e5e5e5', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' };
const inlineBlockFlex = {
    margin: "auto", display: 'flex', alignItems: 'flex-start'
};
const inlineBlockCenter = {
    margin: "auto", alignItems: 'center'
};

export class Shadow extends React.PureComponent<ShadowProps> {
    render() {
        const { childrenComponents, children, type, width } = this.props;

        return (<>
            {(type || type) === 'SHADOW' && (
                <div>
                    <div
                        style={{
                            ...borderWithBoxShadow,
                            ...inlineBlockCenter,
                            ...window.location.pathname == "/" && inlineBlockFlex,
                            padding: 20,
                            maxWidth: window.location.pathname == "/" ? 320 : 1700,
                        }}
                    >
                        {children}
                    </div>
                </div>
            )}
            {(type || type) === 'DOUBLE_SHADOW' && (<div aria-live="polite" >
                {childrenComponents && childrenComponents.map((m: any, i: any) => (
                    <div 
                        key={i}
                        style={{ ...borderWithBoxShadow, padding: 20, margin: '2em auto', maxWidth: 320, textAlign: 'center' }}
                    >
                        <div>
                            {m && m}
                        </div>
                    </div>
                ))
                }
            </div>)}
            {(type || type) === 'NO_SHADOW' && (< > {children}</>)}
            {(type || type) === 'FOOTER' && (<div style={{ width: '100%', ...inlineBlockCenter }}>
                {children}
            </div>)}
        </>);
    }
}

