import React from "react";
import { BrowserRouter as Router, Route, Navigate, Routes } from "react-router-dom";
import SearchAndBadge from "../SearchAndBadge";
import { ContactGroups } from "../ContactGroups/ContactGroups";
import { NewContactGroup } from "../NewContactGroup";
import { ViewContactGroup } from "../ViewContactGroup";
import { AddToolManagers } from "../AddToolManager";
import { IFindContactContainerPropType, IFindContractContainerStateType } from "./FindContactContainerTypes";
import { ToolManagers } from "../ToolManagers";
import { endpoints, authContext, mapResponseToContactGroup, mapResponseToToolManager, getFirstContactGroup, mapPeople } from "../../Broker/endpoints";
import FeedbackHeader from "../FeedbackHeader";
import { MessageBar, MessageBarType } from "@fluentui/react/lib/MessageBar";
import { ToolMangerReadMe } from "../ReadMe/ToolMangersReadme";
import { loadTheme } from "@fluentui/react";
import { CoherenceTheme } from "@coherence-design-system/styles";

const { _user } = authContext as any;
const alias = _user && _user.userName;

export class FindContactContainer extends React.Component<IFindContactContainerPropType, IFindContractContainerStateType> {
    constructor(props: any) {
        super(props);
        const userName = authContext && authContext._user && authContext._user.userName;
        this.state = {
            ...this.getInitialState(),
            setState: (state: any, resolve?: any) => this.setState(state, resolve),
            updateState: (newState: any) => this.setState({ ...this.state, ...newState }),
            ...this.apiCalls(),
            contactCategories: undefined,
            user: userName,
            authContext,
            legalCategory: "apiSearchContactsLegal",
            alias: userName,
            metaData: undefined,
            userUploadHistory: undefined,
            itemDeleted:false
        };

        loadTheme(CoherenceTheme);
    }

    render() {
        return (<>
            <FeedbackHeader  {...this.state} />
            {(this.state.metaData != undefined && typeof this.state.metaData != "string") ?
                <MessageBar>
                    File is being processed, check notifications panel for status.
                </MessageBar> : null
            }
            <Router>
                <Routes>
                    <Route path="/" element={<SearchAndBadge {...this.state} />} />
                    <Route path="/contactgroups" element={<ContactGroups {...this.state} />} />
                    <Route  path="/newcontactgroup" element={<NewContactGroup {...this.state} />} />
                    <Route  path="/viewcontactgroup/:id" element={<ViewContactGroup {...this.state} />} />
                    <Route  path="/toolmanagers" element={<ToolManagers {...this.state} />} />
                    <Route  path="/newtoolmanager" element={<AddToolManagers {...this.state} />} />
                    <Route  path="/toolmanagers/readme" element={<ToolMangerReadMe  {...this.state}/>} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Router>

        </>);
    }

    private getInitialState() {
        return {
            contactGroups: [],
            contactGroupDetails: {},
            toolManagers: [],
            isToolManagerBool: false,
            delayResults: true,
            peopleList: [],
            mostRecentlyUsed: [],
            currentSelectedItems: [],
            contactList: undefined,
            route: "FindYourContact"
        };
    }

    private apiCalls() {
        return {
            isToolManager: async () => await endpoints({ alias:this.state.alias, endpoint: "apiToolManagersAliasToolManager", thenFunc: (response: any) => response.data }),
            apiSearchUsers: async (contact: any) => await endpoints({ contact, endpoint: "apiSearchUser", thenFunc: mapPeople })
                .then((people: any) => {
                    const peopleListObject = {
                        ...this.state.peopleListObject,
                        ...(people && people.map((m: any) => ({ [m.mail]: m })))
                    };
                    const peopleList = Object.values(peopleListObject).map((m: any) => m[Object.keys(m)[0]]);
                    this.setState({
                        peopleListObject,
                        peopleList,
                        mostRecentlyUsed: peopleList
                    });
                    return peopleList
                }),
                apiSearchUserByAlias: async (alias: any) => await endpoints({ alias, endpoint: "apiSearchUserByAlias", thenFunc: mapPeople })
                .then((people: any) => {
                    const peopleListObject = {
                        ...this.state.peopleListObject,
                        ...(people && people.map((m: any) => ({ [m.mail]: m })))
                    };
                    const peopleList = Object.values(peopleListObject).map((m: any) => m[Object.keys(m)[0]]);
                    this.setState({
                        peopleListObject,
                        peopleList,
                        mostRecentlyUsed: peopleList
                    });
                    return peopleList
                }),
            apiSearchContact: async ({ contact, type }: { contact?: any, category?: any, type: string }) => {
                await endpoints({
                    contact: contact ? contact : this.state.user,
                    endpoint: 'apiSearchContact',
                    type,
                    thenFunc: mapPeople
                })
                    .then((contactList: any) => {
                        this.setState({
                            ...contactList && { contactList },
                            ...!contactList && {
                                contactList: [{
                                    imageUrl: '/ContactNotFound.svg',
                                    text: 'CONTACT NOT FOUND',
                                }]
                            }
                        })
                    })
            },
            apiSearchContactsLegal: async ({ contact, category }: { contact?: any, category?: any }) =>
                await endpoints({
                    contact: contact ? contact : this.state.user,
                    endpoint: category ? category : this.state.legalCategory,
                    thenFunc: mapPeople
                })
                    .then((contactList: any) => {
                        this.setState({
                            ...contactList && { contactList },
                            ...!contactList && {
                                contactList: [{
                                    imageUrl: '/ContactNotFound.svg',
                                    text: 'CONTACT NOT FOUND',
                                }]
                            }
                        })
                    })
                    .catch(() => {
                        this.setState({
                            contactList: [{
                                imageUrl: '/ContactNotFound.svg',
                                text: 'CONTACT NOT FOUND',
                            }]
                        })
                    }),

            apiContactGroups: async () => await endpoints({ endpoint: "apiContactGroups", thenFunc: (response: any) => response.data })
                .then((response: { map: (arg0: (m: any) => any) => void; }) =>
                    this.setState({
                        //@ts-ignore
                        contactGroups:
                            response && response.map((m: any) => {
                                const { name, primaryContact, secondaryContact, ossName, ossContact, leader, lastUpdated, owner, lastUpdatedUser } = m;
                                return mapResponseToContactGroup(m, name, primaryContact, secondaryContact, ossName, ossContact, leader, lastUpdated, owner, lastUpdatedUser);
                            })
                    })
                ),
            apiToolManagers: async () => {
                return await endpoints({ endpoint: "apiToolManagers", thenFunc: (response: any) => response.data })
                    .then(((toolManagers: any) => this.setState({ toolManagers })))
            },
            apiContactGroupsIDAlias: async ({ assignmentId }: any) =>
                await endpoints({ id: assignmentId, endpoint: "apiContactGroupsIDAlias", thenFunc: getFirstContactGroup })
                    .then((contactGroupDetails: any) => {
                        this.setState({ contactGroupDetails })
                        return contactGroupDetails;
                    }),
            apiContactGroupsIDDisplayName: async (args: any) => {
                this.setState({ contactGroupDetails: [] });
                const { id } = args;
                return await endpoints({
                    id,
                    endpoint: "apiContactGroupsIDDisplayName",
                    thenFunc: getFirstContactGroup
                })
                    .then((contactGroupDetails: any) => this.setState({ contactGroupDetails }));
            },
            apiToolManagersIDAlias: async ({ id }: any) => await endpoints({
                id,
                endpoint: "apiToolManagersIDAlias",
                thenFunc: (response: any) => response.data
            })
                .then((contactGroupDetails: any) => {
                    this.setState({ contactGroupDetails })
                    return contactGroupDetails
                }),
            apiToolManagersIDDisplayName: async ({ id }: any) => await endpoints({
                id,
                endpoint: "apiToolManagersIDDisplayName",
                thenFunc: (response: any) => [response.data].map(m => mapResponseToToolManager(m))[0]
            })
                .then((contactGroupDetails: any) => this.setState({ contactGroupDetails })),
            post: async ({ id, data, endpoint, method, partitionKey }: any) => {
                await endpoints({ id, endpoint, method, data, partitionKey, thenFunc: () => { } })
                    .then(() => {
                        this.state.apiContactGroups();
                        this.state.apiToolManagers();
                    })
            },
            apiGeneralContactGroupsCategories: async () => {
                return await endpoints({ endpoint: "apiGeneralContactGroupsCategories", thenFunc: (response: any) => response.data })
                    .then((contactCategories: any) => this.setState({ contactCategories }))
            },
            apiUploadContactGroups: async (excelFile: any) => {
                return await endpoints({
                    endpoint: "apiBulkUpload", method: "POST", data: excelFile, headers: {
                        'content-type': "multipart/form-data",
                    }, thenFunc: (response: any) => response.data
                })
                    .then((metaData) => {
                        if (metaData === null || metaData === undefined) return;
                        this.setState({ metaData });
                        return metaData;
                    })
            },
            apiUploadContactGroupsStatus: async () => {
                return await endpoints({
                    endpoint: "apiBulkStatus",
                    thenFunc: (response: any) => response.data
                })
                    .then((metaData) => { this.setState({ metaData }); return metaData; })
            },
            apiUploadContactGroupsUserHistory: async () => {
                return await endpoints({
                    endpoint: "apiBulkUserHistory",
                    method: "GET",
                    thenFunc: (response: any) => response.data
                })
                    .then((userUploadHistory) => userUploadHistory)
            },
            apiSetReadStatus: async (rowKey: string, notificationStatus: string) => {
                return await endpoints({
                    endpoint: "apiSetReadStatus",
                    method: "PATCH",
                    query: `?rowKey=${rowKey}&notificationStatus=${notificationStatus}`,
                    thenFunc: (response: any) => response.data
                })
                    .then((userUploadHistory) => userUploadHistory)
            },
            apiGetWorkAreas: async () => {
                return await endpoints({
                    endpoint: "apiGetWorkAreas",
                    thenFunc: (response: any) => response.data
                })
                    .then((workAreas) => workAreas)
            },
            apiGetServiceAreas: async () => {
                return await endpoints({
                    endpoint: "apiGetServiceAreas",
                    thenFunc: (response: any) => response.data
                })
                    .then((serviceAreas) => serviceAreas)
            },
            apiExportContacts: async () => {
                return await endpoints({
                    endpoint: "apiExportContacts",
                    responseType: 'blob', 
                    thenFunc: (response: any) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'contacts.xlsx');
                        document.body.appendChild(link);
                        link.click();
                    }
                })
            }
        };
    }
}