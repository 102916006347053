import React from "react";
import {
    BaseComponent
} from "@fluentui/react";
import { Shadow } from "./Shadow";
import { ContactPicker } from "./ContactPicker";
import { FindYourContactProps } from "./IProps";

export class FindYourContact extends BaseComponent<FindYourContactProps> {
    render() {
        const {
            currentSelectedItems,
            mostRecentlyUsed,
            peopleList,
            groups,
            textField,
            value
        } = this.props;
        return (
            <>
                {groups && (
                    <>
                        <Shadow
                            type={groups ? "NO_SHADOW" : "SHADOW"}
                        >
                            <ContactPicker
                                {...this.props}
                                peopleList={peopleList}
                                mostRecentlyUsed={mostRecentlyUsed}
                                currentSelectedItems={currentSelectedItems}
                                groups
                                textField={textField}
                                value={value}
                            />
                        </Shadow>
                    </>
                )}
            </>
        );
    }
}
