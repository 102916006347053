import axios from 'axios';
import { adalFetch, AuthenticationContext } from "react-adal";
import { Key } from "../Components/ContactGroups/Columns";


export const adalConfig = {
    tenant: "72f988bf-86f1-41af-91ab-2d7cd011db47",
    clientId: process.env.REACT_APP_UI_APP_ID || "",
    endpoints: {
        api: `api://${process.env.REACT_APP_API_APP_ID}`
    },
};

type IAuthContextType = { _user: any; } & any;
export const authContext: IAuthContextType = new AuthenticationContext(adalConfig);


export const adalApiFetch = (fetch: any, url: any, options: any) => adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const endpoints = ({ alias, id, endpoint, type, contact, thenFunc, method, data, headers, query, partitionKey, responseType }: any) => {
    const config = {
        method: method ? method : 'GET',
        data,
        headers,
        responseType
    };
    //@ts-ignore
    const endpointUri = ({
        apiContactGroups: (method === 'PUT') ? `${endpointBaseUrl}/api/Sme/Assignment?assignmentId=${id}` : (method === 'POST') ? `${endpointBaseUrl}/api/Sme/Assignment` : (method === 'DELETE') ? `${endpointBaseUrl}/api/Sme/Assignment?id=${id}&partitionKey=${partitionKey}` : `${endpointBaseUrl}/api/Sme/Assignments`,
        apiContactGroupsIDAlias: `${endpointBaseUrl}/api/Sme/Assignments/${id}`,
        apiContactGroupsIDDisplayName: `${endpointBaseUrl}/api/Sme/Assignments/${id}`,
        apiSearchContact: `${endpointBaseUrl}/api/Search/Contacts/${type}/${contact}`,
        apiSearchContactsLegal: `${endpointBaseUrl}/api/Search/Contacts/Legal/${contact}`,
        apiSearchContactsOSS: `${endpointBaseUrl}/api/Search/Contacts/OSS/${contact}`,
        apiSearchUser: `${endpointBaseUrl}/api/Search/Users/${contact}`,
        apiSearchUserByAlias: `${endpointBaseUrl}/api/Search/Users/Alias/${alias}`,
        apiToolManagers: (method === 'DELETE') ? `${endpointBaseUrl}/api/ToolManagers/WorkAreaOwnership` : `${endpointBaseUrl}/api/ToolManagers`,
        apiToolDeleteManager: `${endpointBaseUrl}/api/ToolManagers?id=${id}${(partitionKey != null) ? `&partitionKey=${partitionKey}` : ""}` ,
        apiToolManagersAliasToolManager: `${endpointBaseUrl}/api/ToolManagers/${alias}/ToolManager`,
        apiToolManagersIDAlias: `${endpointBaseUrl}/api/ToolManagers/${id}/alias`,
        apiToolManagersIDDisplayName: `${endpointBaseUrl}/api/ToolManagers/${id}/DisplayName`,
        apiGeneralContactGroupsCategories: `${endpointBaseUrl}/api/GeneralContactGroups/categories`,
        apiBulkUpload: `${endpointBaseUrl}/api/ImportContact/Upload`,
        apiBulkStatus: `${endpointBaseUrl}/api/ImportContact/Status`,
        apiBulkUserHistory: `${endpointBaseUrl}/api/ImportContact/UserHistory`,
        apiSetReadStatus: `${endpointBaseUrl}/api/ImportContact/setReadStatus${query}`,
        apiGetWorkAreas: `${endpointBaseUrl}/api/KnowledgeGraph/WorkAreas`,
        apiGetServiceAreas: `${endpointBaseUrl}/api/KnowledgeGraph/ServiceAreas`,
        apiExportContacts: `${endpointBaseUrl}/api/ImportContact/Export`
    })[endpoint];

    return adalApiFetch(axios, endpointUri, config)
        .then(thenFunc)
        .catch((error: any) => { throw error; });
};

export const endpointBaseUrl = process.env.REACT_APP_endpointBaseUrl

export function mapResponseToToolManager(m: any): any {
    return {
        ...m,
        "Tool Manager": m.toolManager,
        "Added By": m.addedBy,
        "Last Updated": m.lastUpdated,
        "Last Updated By": m.lastUpdatedBy
    };
}

type IContactGroupType = {
    [key in Key]: string;
};


export function mapResponseToContactGroup(contactGroupResponse: any, name: any, primaryContact: any, secondaryContact: any, ossName: any, ossContact: any, leader: any, lastUpdated: any, owner: any, lastUpdatedUser: any): IContactGroupType {
    interface IContactGroupType {
        Name: string;
        "Primary Contact": string;
        "Secondary Contact": string;
        "OSS Name": string;
        "OSS Contact": string;
        Leader: string;
        "Last Updated": string;
        Owner: string;
        "Last Updated User": string;
    }
    const mappedContactGroup: IContactGroupType = {
        
        Name: name ? name : "-",
        "Primary Contact": primaryContact ? primaryContact : "-",
        "Secondary Contact": secondaryContact ? secondaryContact : "-",
        "OSS Name": ossName ? ossName : "-",
        "OSS Contact": ossContact ? ossContact : "-",
        Leader: leader ? leader : "-",
        "Last Updated": lastUpdated ? lastUpdated : "-",
        Owner: owner ? owner : "-",
        "Last Updated User": lastUpdatedUser ? lastUpdatedUser : "-",
    };

    const retContactGroup = {
        ...contactGroupResponse,
        ...mappedContactGroup
    }
    return retContactGroup;
}

export const getFirstContactGroup = (response: any): IContactGroupType =>
    [response.data]
        .map(
            m => {
                const { name, primaryContact, secondaryContact, ossName, ossContact, leader, lastUpdated, owner, lastUpdatedUser } = m;
                return mapResponseToContactGroup(m, name, primaryContact, secondaryContact, ossName, ossContact, leader, lastUpdated, owner, lastUpdatedUser) as IContactGroupType;
            }
        )[0];

export const mapPeople = (response: any) => {
    const res = response.data.length ? response.data[0] : response.data;
    const people = [res].map((m: any) => ({
        ...m,
        text: m.displayName,
        secondaryText: m.jobTitle,
        imageUrl: m.picture
    }));
    return people;
};
