import React from 'react';
import { CoherenceHeader, CoherencePanel, CoherencePanelSize, initializeOBFeedback, NotificationListItem } from '@coherence-design-system/controls';
import { ActionButton, IStyle, mergeStyleSets } from '@fluentui/react';
import { ISearchAndBadgePropTypes } from './SearchAndBadge';
import { appInsights } from '../AppInsights';
import { BulkUploadContent } from './BulkImport/BulkUploadContent';
import { checkStatus } from '../CheckStatus';
import dayjs from 'dayjs';


interface State {
    ocvButtonIsFocused: boolean;
    settingsPanelOpen: boolean;
    notificationCount: number;
    notificationItems: NotificationListItem[];
}
const _Styles = mergeStyleSets({
    root: {
        marginLeft: '-10px',
        marginRight: '-10px'
    } as IStyle
});

const subjectIcon = [
    "ActivateOrders", // Accepted
    "ProgressRingDots", // InProgress
    "ErrorBadge", // Failed
    "Completed", // Success
    "Error" // PartialSuccess
];

const summaryBodyText = (item: any): string => {
    switch (item.status) {
        case 0:
        case 1: {
            return `${item.rowsProcessed} of ${item.totalNumberOfRows} processed.`
        }
        case 2: {
            return "failed"
        }
        case 4: {
            return `Completed with ${item.numberOfErrors} error${item.numberOfErrors !== 1 ? "s" : ""}.`
        }
        default: {
            return ""
        }
    }
}

export default class extends React.Component<ISearchAndBadgePropTypes, State> {

    constructor(props: ISearchAndBadgePropTypes) {
        super(props);
        this.state = { ocvButtonIsFocused: false, settingsPanelOpen: false, notificationCount: 0, notificationItems: [] }
        const { user } = this.props;

        if (!this.props.groups && this.props.isToolManagerBool) {
            console.log()
        } else {

        }

        // THE INITIALIZATION WILL ENABLE THE OCV FEEDBACK BUTTON
        initializeOBFeedback(
            2609,
            String(process.env.REACT_APP_ocvFeedbackEnvironment), // running-environment
            '/officebrowserfeedback.min.js',
            '/officebrowserfeedback_vertical.min.css',
            '/intl/',
            undefined,
            user,
            appInsights.context.telemetryTrace.traceID
        );
    }

    componentDidUpdate(prevProps: ISearchAndBadgePropTypes) {
        if (prevProps.isToolManagerBool !== this.props.isToolManagerBool) {
            this._notificationPanelOpened();
        }
    }

    SettingsBar() {
        return (<div className={_Styles.root}>
            <ActionButton iconProps={{ iconName: 'Settings' }} href="/contactgroups" title='Add or edit contactss'>
                Add or edit contacts
            </ActionButton>
            <br />
            <ActionButton iconProps={{ iconName: 'Lock' }} href="/toolmanagers" title='Add or edit tool manager'>
                Add or remove tool managers
            </ActionButton>
            <br />
            <ActionButton iconProps={{ iconName: 'Upload' }} onClick={() => this.setState({ settingsPanelOpen: !this.state.settingsPanelOpen })} title='Import Contacts'>
                Import Contacts
            </ActionButton>
            <br />
            <ActionButton iconProps={{ iconName: 'Help' }} href="/toolmanagers/readme" title='Help'>
                Help
            </ActionButton>
        </div>)
    }

    AdminHeaderSettings() {
        const elements: HTMLCollectionOf<Element> = document.getElementsByClassName('rightItemButtonIcon-183');
        for (var i = 0; i < elements.length; i++) {
            const element = elements[i] as HTMLElement;
            element.style.color = 'rgb(239 242 243)';
        }
        const elementprod: HTMLCollectionOf<Element> = document.getElementsByClassName('rightItemButtonIcon-140');
        for (var i = 0; i < elementprod.length; i++) {
            const element = elementprod[i] as HTMLElement;
            element.style.color = 'rgb(239 242 243)';
        }
        return {
            buttonSettings: {
                title: 'Admin Tools',
                ariaLabel: 'Admin Tool'
            },
            panelSettings: {
                body: this.SettingsBar(),
                titleText: "Admin Tools"
            }
        }
    }
    NotificationAriaLabelText() {
        return this.state.notificationCount > 0 && this.state.notificationCount <= 99
            ? `${this.state.notificationCount} new notification${this.state.notificationCount !== 1 ? 's' : ''}`
            : this.state.notificationCount > 0
                ? 'Over 99 new notifications'
                : 'Notifications';
    }

    NotificationSettings() {
        return {
            buttonSettings: {
                title: 'Notification',
                ariaLabel: this.NotificationAriaLabelText()
            },
            panelSettings: {
                titleText: 'Upload History',
                items: this.state.notificationItems,
                newNotifications: this.state.notificationCount,
                updateItem: this._updateItem,
                onPanelOpened: this._notificationPanelOpened,
            }
        }
    }

    render() {


        return (
            <>
                <CoherenceHeader
                    headerLabel={'header'}
                    appNameSettings={{
                        label: 'CELA Contacts'
                    }}
                    farRightSettings={
                        {
                            notificationsSettings: (!this.props.groups && this.props.isToolManagerBool) ? this.NotificationSettings() : undefined as any,
                            settingsSettings: (!this.props.groups && this.props.isToolManagerBool) ? this.AdminHeaderSettings() : undefined,
                            feedbackSettings: {
                                buttonSettings: {
                                    title: 'Feedback',
                                    ariaLabel: 'Feedback'
                                },
                                panelSettings: {
                                    ocvButtonIsFocused: this.state.ocvButtonIsFocused,
                                    onClick: () => {
                                        appInsights.trackEvent({ name: 'Clicked on feedback' }, {
                                            callingUser: this.props.alias, // who is logged in
                                            lookupAlias: this.props.user, // who they looked up
                                            callingAppId: process.env.REACT_APP_callingAppIdTelemetry
                                        });
                                        this.setState({
                                            ocvButtonIsFocused: true
                                        });
                                        return true;
                                    },
                                    launchOptions: {
                                        categories: {
                                            show: true,
                                            customCategories: ['User', 'Admin']
                                        }
                                    }
                                }
                            }
                        }}
                />
                <CoherencePanel
                    panelSize={CoherencePanelSize.medium}
                    titleText={'Bulk Update'}
                    isOpen={this.state.settingsPanelOpen}
                    onDismiss={() => this.setState({ settingsPanelOpen: false })}
                    hasCloseButton={true}
                    closeButtonAriaLabel={'Close'}>
                    <BulkUploadContent apiUploadContactGroups={this.props.apiUploadContactGroups} onUploadComplete={() => { this.GetImportHistory(); this.setState({ settingsPanelOpen: false }) }} apiExportContacts={this.props.apiExportContacts} />
                </CoherencePanel>
            </>
        );
    }

    private GetImportHistory() {
        this.props.apiUploadContactGroupsUserHistory()
            .then((data) => {
                if (!data) return;
                this.setState({ notificationCount: data.filter((item: any) => item.read === false).length });
                const initialNotifications = data.map((item: any): NotificationListItem => {
                    return {
                        itemKey: item.rowKey,
                        timeDelivered: dayjs(item.dateAdded),
                        status: item.read ? "read" : "unread",
                        subjectIcon: subjectIcon[item.status],
                        subjectHeader: "Contacts",
                        displayStatus: "new",
                        actionRequiredText: (item.status === 2 || item.status === 4) ? "Download file to see errors" : undefined,
                        actionRequiredLink: item.sasUrl,
                        summaryBodyText: summaryBodyText(item)
                    }
                });
                this.setState({ notificationItems: initialNotifications });
            })
    }

    private _updateItem = (itemKey: string, dStatus: NotificationListItem['displayStatus'], rStatus?: NotificationListItem['status']) => {
        if (this.state.notificationCount === 0 && rStatus === "read" || dStatus === "dismiss") return;
        const list = [...this.state.notificationItems];
        list.forEach((item) => {
            if (item.itemKey === itemKey) {
                item.displayStatus = dStatus;
                if (rStatus) {
                    item.status = rStatus;
                }
            }
        });
        this.setState({ notificationItems: list });
        this.props.apiSetReadStatus(itemKey, `${rStatus}`)
        const count = rStatus === "read" ? this.state.notificationCount - 1 : this.state.notificationCount + 1
        this.setState({ notificationCount: count < 0 ? 0 : count });
    }

    private _notificationPanelOpened = () => {
        this.GetImportHistory();

        checkStatus.start(this.props.apiUploadContactGroupsStatus, () => {
            this.GetImportHistory();
        }, 5000)
    }
}