import { FontIcon, MessageBar, MessageBarType } from "@fluentui/react";
import React from "react"
import { AsideNav } from "./AsideNav"

const helpWrapper: React.CSSProperties = {
    paddingLeft: '22rem',
    paddingRight: '2rem',
    maxWidth: '1300px',
};

const exampleWrapper: React.CSSProperties = {
    background: '#fbfbfb',
    padding: '1rem',
    margin: '1rem 0',
    display: 'flex',
    justifyContent: 'center'
};

export const ToolMangerReadMe = ({ setState, isToolManager }: any) => {
    isToolManager()
        .then((res: any) => { setState({ isToolManagerBool: res }); });
    return (
        <section style={{ height: '100%' }}>
            <div>
                <AsideNav />
                <div style={helpWrapper}>
                    <h1>Help</h1>
                    <h2 id="contacts">Contacts</h2>
                    <p>
                        <i>Before starting, make sure you have the mappings (that is name of the leader) needed to start creating or editing a contact.
                            In the contact screen if you notice a message saying No Contacts assigned, please follow the instructions to <a href="#tool-manager-mapping">create a mapping to a leader</a>. </i>
                    </p>
                    <div style={exampleWrapper}>
                        <MessageBar messageBarType={MessageBarType.warning} styles={{ root: { maxWidth: '300px' } }}>
                            No contacts assigned.
                        </MessageBar>
                    </div>
                    <h3 id="create-contact">Create Contact</h3>
                    <p>
                        On the <a href= "/contactgroups">contact page</a>, click <strong>Add Contact</strong><br />
                        For a contact there are only two fields required: <strong>Leader</strong> and <strong>Contact</strong>.
                        <div style={exampleWrapper}>
                            <img src="/images/new-contact.jpg" />
                        </div>
                        in the text field type the users name or alias to look up a user.
                        <div style={exampleWrapper}>
                            <img src="/images/new-contact-search.jpg" />
                        </div>
                        Once you have added the information needed for the contact click <strong>OK</strong>.
                    </p>
                    <h3 id="edit-contact">Edit Contact</h3>
                    <p>On the contact page click the <strong>Edit</strong> icon  <FontIcon aria-label="view/edit" iconName="OpenEnrollment" />
                        <br />
                        Here you can make any updates needed like changing the contact for a leader or changing the contact priority. Once completed click <strong>Update</strong>. </p>
                    <div style={exampleWrapper}>
                        <img src="/images/edit-contact.jpg" />
                    </div>
                    <h2 id="tool-managers">Tool Managers</h2>
                    <p>
                        <i>* In this new update to the FYLC tool we now only display contacts you are mapped to by leader. </i>
                    </p>
                    <h3 id="add-tool-manager">Add Tool Manger</h3>
                    <p>To give only read access to a user, the only required field is <strong>Tool Manger</strong>.</p>
                    <div style={exampleWrapper}>
                        <img src="/images/new-tool-manager.jpg" />
                    </div>
                    <h3 id="tool-manager-mapping">Create Mapping To Leader</h3>
                    <p>In the Tool Mangers page click <strong>Add new tool manager</strong>,  then enter the leader's name you wish to map to. Next, enter your name in the Tool Mangers field or the person for which you wish to create a mapping, and then click <strong>OK</strong>. </p>
                    <p>You will now be able to see and edit contacts for this leader.</p>
                    <div style={exampleWrapper}>
                        <img src="/images/new-tool-manager-mapping.jpg" />
                    </div>
                </div>
            </div>
        </section>
    )
}