import React from "react";
import { Layout } from "./Layout";
import { Shadow } from "./Shadow";
import { ContactPicker } from "./ContactPicker";
import { Spinner, SpinnerSize, IPersonaSharedProps, IconButton } from "@fluentui/react";
import { PersonaBadge } from "./PersonaBadge";
import { Link } from "react-router-dom";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../AppInsights";
import logo from "../Image/Logo.png";
//import "../Css/celaContactFooter.css";
export type ISearchAndBadgePropTypes = {
    setState: any;
    apiSearchUsers: (userName: any) => Promise<any[]>;
    apiSearchContactsLegal: (contact: any, category?: any) => Promise<void>;
    isToolManager: () => Promise<boolean>;
    apiContactGroups: () => Promise<void>;
    apiToolManagers: () => Promise<void>;
    apiUploadContactGroups: (excelFile: any) => Promise<void>;
    apiExportContacts: () => Promise<void>;
    apiUploadContactGroupsStatus: () => Promise<void>;
    apiUploadContactGroupsUserHistory: () => Promise<[]>;
    apiSetReadStatus: (itemKey: string, rStatus: string) => Promise<void>;
    currentSelectedItems: any[];
    groups?: any[];
    peopleList: any[];
    mostRecentlyUsed: any[];
    contactList?: any[];
    isToolManagerBool: boolean;
    contactGroupDetails?: any;
    peopleListObject?: any;
    toolManagers: any[];
    authContext: any;
    user: any;
    legalCategory: string;
    alias: string,
    metaData: any
};

class SearchAndBadge extends React.Component<ISearchAndBadgePropTypes> {
    constructor(props: any) {
        super(props);
        const { setState, apiSearchUsers, apiSearchContactsLegal, isToolManager, authContext } = this.props;
        const userName = authContext && authContext._user && authContext._user.userName;
        apiSearchUsers(userName)
            .then((main: any) => setState({
                currentSelectedItems: {
                    main
                }
            }));
        apiSearchContactsLegal({});
        isToolManager()
            .then((res: any) => { setState({ isToolManagerBool: res }); }); 
    }
    render() {
        const { apiSearchUsers, apiSearchContactsLegal, setState, currentSelectedItems, groups,
            peopleList, mostRecentlyUsed, contactList, isToolManagerBool, user, alias } = this.props;


        return <main id='main' aria-label="CELA Contacts" tabIndex={-1}>
           <Layout title={"CELA Contacts"}>
                <Shadow
                    type={groups ? "NO_SHADOW" : "SHADOW"}
                >
                    <ContactPicker
                        {...this.props}
                        peopleList={peopleList}
                        mostRecentlyUsed={mostRecentlyUsed}
                        groups={groups ? true : false}
                        apiSearchUsers={apiSearchUsers}
                        apiSearchContactsLegal={apiSearchContactsLegal}
                        onRemoveItem={this.onRemoveItem(setState)}
                        currentSelectedItems={this.currentSelectedItems(currentSelectedItems)}
                        onItemSelected={this.onItemSelected(setState)}
                    />

                </Shadow>
                <Shadow
                    type={"NO_SHADOW"}
                >
                    {!contactList && (
                        <div>
                        <Spinner
                            style={{ height: 465 }}
                            size={SpinnerSize.large}
                            />
                            
                            <span role="alert" aria-label="Loading Contact">Loading Contact...</span>
                        </div>
                    )}
                </Shadow>
                <Shadow
                    type={"DOUBLE_SHADOW"}
                    childrenComponents=
                    {!groups &&
                        contactList &&
                        contactList.length &&
                        contactList.map((c: IPersonaSharedProps | undefined, i: any) => (<PersonaBadge key={i} person={c} user={user} alias={alias} />))}
                >
                </Shadow>
                <footer className="i_a_beed2cf1" style={{position:"relative",zIndex:7}}>
                    <div className="simpleFooterContainer" role="contentinfo" style={{backgroundColor:"rgb(0, 120, 212)",
                    minHeight:"48px",display:"flex",flexWrap:"wrap",justifyContent:"space-between",height:"auto",overflow:"hidden",
                    padding:"0px 24px"}}>
                        <div className="simpleFooterLogoSlot" style={{display:"flex",fontWeight:600}}>
                            <div className="simpleFooterLogoItem" style={{margin:"8px 0px 4px",maxHeight:"32px",lineHeight:"1.4em"}}>
                                <img className="simpleFooterLogoImage" alt="Microsoft" style={{maxHeight:"32px",marginRight:"8px"}} src={logo}/>
                            </div>
                        </div>
                        <div className="simpleFooterLinkSlots-402" style={{padding:"0px",display:"flex",flexWrap:"wrap"}}>
                            <div className="simpleFooterItem-403" style={{lineHeight:"19px",
                            textAlign:"left",fontFamily:'Segoe UI,sans-serif', fontSize:"14px",fontWeight:400,display:"flex",alignItems:"center"}}>
                                <a className="privacy_link" href="https://privacy.microsoft.com/en-US/data-privacy-notice"
                                    target="_blank" rel="noreferrer" style={{color:"white",textDecoration:"none"}}>Privacy Statement</a>
                            </div>
                        </div>
                    </div>
                </footer>
            </Layout>
        </main>
    }

    private currentSelectedItems(currentSelectedItems: any): any {
        return currentSelectedItems ? currentSelectedItems.main : [];
    }

    private onRemoveItem(setState: any): any {
        return () => setState({ currentSelectedItems: { main: [] } });
    }

    private onItemSelected(setState: any): any {
        return (selectedItem?: any | undefined) => {
            setState({
                contactList: undefined,
                user: selectedItem.mail,
                ...selectedItem && { currentSelectedItems: { main: [selectedItem] } }
            });
            return selectedItem && this.props.apiSearchContactsLegal({ contact: selectedItem.mail })
                .then((data: any) => {
                    data &&
                        setState({
                            contactList: data,
                            contactGroupDetails: {
                                ...this.props.contactGroupDetails,
                                'name': data.mail
                            }
                        });
                    return selectedItem ? selectedItem : null;
                });
        };
    }
}

export default withAITracking(reactPlugin, SearchAndBadge, "Home Screen: Search and Badge");