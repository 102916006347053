import React from "react";
import { IconButton } from "@fluentui/react";
import { ShadowProps } from "./Shadow";
import { Link } from "react-router-dom";
import { DeleteDialog } from "./DeleteDialog";

export class ActionButtons extends React.PureComponent<ShadowProps> {
    
    render() {
        const { m, editMode, setState, toolmanager, post, itemDeleted } = this.props;
        return (
            <section>
                {
                    !toolmanager && <>
                        <Link
                            to={{
                                pathname: editMode ? '/contactgroups' : `/viewcontactgroup/${m.assignmentId}`,
                            }}
                            onClick={() => setState({ editMode: false })}
                        >
                            <IconButton
                                styles={{ root: {} }}
                                iconProps={{ iconName: this.props.viewIcon }}
                                title={this.props.viewText}
                                ariaLabel={this.props.viewText}
                            />
                        </Link>
                    </>
                }
                <DeleteDialog toolmanager={toolmanager} post={post} setState={setState} data={m} itemDeleted={itemDeleted} />
            </section>
        );
    }
}
