type CheckStatusType = {
    timeoutID: number | undefined;
    rowsProcessed: number;
    numberOfTries: number;
    start: (apiCall: () => Promise<void>, callback: () => void, timeout: number) => void;
    cancel: () => void;
}

export const checkStatus: CheckStatusType = {
    timeoutID: undefined,
    rowsProcessed: 0,
    numberOfTries: 0,
    start: function (apiCall: () => Promise<void>, callback: () => void, timeout: number) {
        if (typeof this.timeoutID === "number") {
            this.cancel();
        }

        if (this.numberOfTries > 5) {
            this.cancel();
            return;
        }

        apiCall();
        
        this.timeoutID = window.setTimeout(() => {
            callback();
            apiCall().then((data: any) => {
                if (data === undefined || typeof data === "string") return;

                if (this.rowsProcessed === data.rowsProcessed) {
                    this.numberOfTries++;
                } else {
                    this.numberOfTries = 0
                }

                this.rowsProcessed = data.rowsProcessed;
                this.start(apiCall, callback, timeout);
            })

        }, timeout);
    },
    cancel: function () {
        clearTimeout(this.timeoutID);
    }
}