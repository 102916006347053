export type TextFieldType = { label: string, key: string, secondKey?: string, required: boolean, displayErrorMessage?: boolean, isPicker?: boolean, isDropDown?: boolean, isMultiplePeoplePicker?: boolean, isPeoplePicker?: boolean, propKey?: string, disable?: boolean, displayDefault?: boolean };

export const textFields: TextFieldType[] = [
    { label: "Work Area", key: "workAreaId", secondKey: "workAreaName", required: true, isPicker: true, propKey: "workAreas",displayDefault:true },
    { label: "Leader", key: "leadsAlias", required: true, isPeoplePicker: true },
    { label: "Contact", key: "smeAlias", required: true, isPeoplePicker: true },
    { label: "Contact Priority", key: "communicationPriority", required: false, isDropDown: true, propKey: "contactPriorities" },
    { label: "Area of Service", key: "serviceAreaId", secondKey: "serviceAreaName", required: false, isPicker: true, propKey: "areaOfService" },
];

export enum contactPriority {
    NONE = 0,
    PRIMARY = 1,
    SECONDARY = 2
}

export const contactPriorities = [
    {
        key: contactPriority.NONE,
        text: "None"
    },
    {
        key: contactPriority.PRIMARY,
        text: "Primary"
    },
    {
        key: contactPriority.SECONDARY,
        text: "Secondary"
    }
]