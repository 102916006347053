import React from "react";
import { contactPriorities, textFields } from "../smeHelpers";
import { Group } from "./Group";

export class ViewContactGroup extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        const { setState, apiContactGroupsIDDisplayName, isToolManager, apiGetWorkAreas, apiGetServiceAreas } = this.props
        document.title = "Edit Contact";
        this.state = {
            workAreas: [],
            areaOfService: []
        }
        const param = "_CONTACT_GROUP";
        const id = window.location.pathname.split('/').pop();
        setState({
            route: `VIEW${param}`,
            currentSelectedItems: [],
            contactList: [],
            toolManagers: [],
            contactGroupDetails: undefined
        });
        apiContactGroupsIDDisplayName({ id }).then(() => this.setState({ add: true }));

        Promise.all([apiGetWorkAreas(), apiGetServiceAreas(), isToolManager()]).then((values) => {
            const [workAreas, areaOfService, isToolManager] = values;

            setState({ isToolManagerBool: isToolManager });

            workAreas.map((area: any) => {
                this.state.workAreas.push({
                    key: area.id,
                    name: area.name
                })
            })

            areaOfService.map((area: any) => {
                this.state.areaOfService.push({
                    key: area.name,
                    name: area.name
                })
            })
        });
    }
    render() {
        return (
            <main id='main' tabIndex={-1}>
                <Group
                    {...this.props}
                    title={"Edit Contact"}
                    textFields={textFields}
                    workAreas={this.state.workAreas}
                    contactPriorities={contactPriorities}
                    areaOfService={this.state.areaOfService}
                    groupDetails={this.props.contactGroupDetails}
                    CONTACT
                    ADD={this.state.add}
                    EDIT
                />
            </main>
        );
    }
}
