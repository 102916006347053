import React from "react";
import { ContactGroup } from "./ContactGroups/ContactGroup";
import { ActionButtons } from "../Components/ActionButtons";
export class ToolManagers extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        document.title = "Tool Managers";
        const { apiToolManagers, apiContactGroups, setState,isToolManager } = this.props;
        apiToolManagers();
        isToolManager()
        .then((res: any) => { setState({ isToolManagerBool: res }); });
    }

    render() {
        const { links, toolManagers } = this.props;
        return (
            <ContactGroup
                {...this.props}
                title={"Tool Managers"}
                toolmanager={true}
                width={900}
                columns={this.toolManagerColumns}
                items={this.toolManagerItems(toolManagers)}
            />
        );
    }

    private readonly toolManagerColumns = [
        {
            fieldName: "workAreaName",
            key: "workAreaName",
            maxWidth: 100,
            name: "Work Area"
        },
        {
            fieldName: "leadsName",
            key: "leadsName",
            minWidth: 70,
            maxWidth: 300,
            name: "Leader"
        },
        {
            fieldName: "toolManagerName",
            key: "toolManagerName",
            minWidth: 70,
            maxWidth: 300,
            name: "Tool Manager"
        },
        {
            fieldName: "lastUpdatedByUserName",
            key: "lastUpdatedByUserName",
            minWidth: 70,
            maxWidth: 300,
            name: "Last Updated By"
        },
        {
            fieldName: "lastUpdatedDateFormatted",
            key: "lastUpdatedDateFormatted",
            minWidth: 70,
            maxWidth: 300,
            name: "Last Updated On"
        },
        {
            fieldName: "Actions",
            key: "Actions",
            minWidth: 90,
            name: "Actions"
        }
    ];

    private toolManagerItems(toolManagers: any): any {
        return toolManagers
            ? toolManagers
                .filter((f: any) => {
                    return this.props.filterText
                        ? JSON.stringify(f)
                            .toLowerCase()
                            .includes(this.props.filterText.toLowerCase())
                        : true;
                })
                .map((m: {
                    toolManager: any;
                }) => ({
                    TOOL_MANAGERS: m.toolManager,
                    ...m,
                    Actions: (<>
                        <ActionButtons {...this.props} toolmanager m={m} actionOnclick={({ actionType, row }: {
                            actionType: string;
                            row: any;
                        }) => () => {
                            this.props.setState({
                                contactGroupDetails: undefined
                            });
                            //@ts-ignore
                            return {
                                VIEW: () => this.props.apiToolManagersIDDisplayName({
                                    id: row.id
                                }),
                                EDIT: () => this.props.apiToolManagersIDAlias({ id: row.id }),
                                DELETE: () => {
                                    this.props.setState({
                                        contactGroups: [],
                                        groupDetails: [],
                                        toolManagers: []
                                    });
                                    this.props.post({
                                        id: row.id,
                                        method: "DELETE",
                                        endpoint: "apiToolManagers"
                                    });
                                }
                            }[actionType]();
                        }} />
                    </>)
                }))
            : [];
    }
}
