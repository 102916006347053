import React from "react";
import {
    ShimmeredDetailsList,
    IDetailsRowProps,
    IDetailsRowStyles,
    DetailsRow,
    getTheme,
    TextField,
    MessageBar,
    MessageBarType
} from "@fluentui/react";
import { ShadowProps, Shadow } from "../Shadow";
import { Layout } from "../Layout";
import { ContactGroupNavButtons } from "./ContactGroupNavButtons";
import { NavButtons } from "../NavButtons";
import { contactPriority } from "../../smeHelpers";
import { couldStartTrivia } from "typescript";

export class ContactGroup extends React.PureComponent<ShadowProps> {
    render() {
        const { items, title, columns, width, toolmanager, setState, alias, apiUploadContactGroups, onUploadComplete, apiExportContacts, itemDeleted } = this.props;

        return (
            <Layout
                title={title}
            >
                {itemDeleted &&
                    <MessageBar
                        messageBarType={MessageBarType.success}
                        isMultiline={false}
                        onDismiss={() => setState({ itemDeleted: false })}
                        dismissButtonAriaLabel="Close"
                        styles={{
                            root: {
                                margin: '0 auto',
                                maxWidth: '300px'
                            }
                        }}>
                        {toolmanager ? "Tool Manager Deleted" : "Contact Deleted"}
                    </MessageBar>}

                {groupNavButtons(width, toolmanager, alias, apiUploadContactGroups, onUploadComplete, apiExportContacts)}
                {this.filterAndTable(width, toolmanager, setState, columns, items)}
                {groupNavButtons(width, toolmanager, alias, apiUploadContactGroups, onUploadComplete, apiExportContacts)}

            </Layout>
        );
    }
    private _onRenderRow = (props: IDetailsRowProps | undefined): JSX.Element => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            const shouldAlternateRowColor = props.itemIndex % 2 === 0;
            if (shouldAlternateRowColor) {
                customStyles.root = { backgroundColor: getTheme().palette.themeLighterAlt };
                return (
                    <DetailsRow
                        {...props}
                        styles={customStyles}
                    />
                );
            }
            return (
                <DetailsRow
                    {...props}
                />
            );
        }
        return <div />;
    };

    private renderItemColumn = (data: any, id: any, field: any) =>
        (<div title={field && field.fieldName && data[field.fieldName] || "No Value"}> {field && field.fieldName && data[field.fieldName] } </div>);

    private filterAndTable(width: any, toolmanager: boolean, setState: any, columns: any, items: any) {
        if (!toolmanager) {
            items.map((contact: any) => {
                contact.communicationPriority = contactPriority[contact.communicationPriority].toLowerCase().charAt(0).toUpperCase() + contactPriority[contact.communicationPriority].toLowerCase().slice(1);
                
                return contact;
            })
        }

        return <Shadow width={width ? width : 400} type={"SHADOW"}>
            <>
                <TextField data-testid='toolmanagerssearch' styles={{ root: { margin: 10, padding: 0 } }} onChange={e => {
                    const { nativeEvent: { target } } = e;
                    const { defaultValue } = target as any;
                    setState({
                        filterText: defaultValue
                    });
                }} defaultValue={this.props.filterText} onRenderLabel={() => null} placeholder="Search everything..." ariaLabel="Search everything" aria-label="Search everything" iconProps={{ iconName: "zoom" }} />
                {(this.props.items.length === 0 && this.props.contactsLoaded) && <MessageBar messageBarType={MessageBarType.warning}>
                    No contacts assigned.
                </MessageBar>}
                <div data-testid='toolmanagers' aria-live="polite" aria-label={`${this.props.items ? this.props.items.length : '0'} search results available`}>
                    <ShimmeredDetailsList selectionMode={0} setKey="items" items={this.props.items} columns={columns} enableShimmer={items && !items.length} onRenderRow={this._onRenderRow} onRenderItemColumn={this.renderItemColumn} onColumnHeaderClick={this.onColumnHeaderClick()} />
                </div>
            </>
        </Shadow>;
    }

    private onColumnHeaderClick() {
        type IColumnType = {
            key: any;
        };

        return (event?: any, column?: IColumnType) => {
            if (column) {
                const sortFunction: any = (a: IColumnType[], b: IColumnType[]) => {
                    var x = a[column.key];
                    var y = b[column.key];

                    if (x === null) return 1;
                    if (y === null) return -1;

                    return x < y ? 1 : x > y ? -1 : 0;
                };
                this.props.setState({
                    //@ts-ignore
                    sorted: column.key,
                    contactGroups:
                        //@ts-ignore
                        this.props.sorted && this.props.sorted === column.key
                            ? this.props.contactGroups.reverse()
                            : this.props.contactGroups.sort(sortFunction)
                });
            }
        };
    }
}
function groupNavButtons(width: any, toolmanager: any, alias: string, apiUploadContactGroups: any, onUploadComplete: any, apiExportContacts: any) {
    return <Shadow width={width ? width : 400} type={"NO_SHADOW"}>
        {!toolmanager ? <ContactGroupNavButtons alias={alias} apiUploadContactGroups={apiUploadContactGroups} onUploadComplete={onUploadComplete} apiExportContacts={apiExportContacts} /> : <NavButtons alias={alias} />}
    </Shadow>;
}

