import React from "react"

const asideStyles: React.CSSProperties = {
    position: 'fixed',
    height: '100%',
    fontSize: '16px',
    maxWidth: '20rem',
    width:'100%',
    borderRight: '1px solid #ddd',
    fontFamily: 'sans-serif'
}

const basicListStyles: React.CSSProperties = {
    listStyleType: 'none',
    padding: '0 .8rem'
}

const activeLinkStyles: React.CSSProperties = {
    color: '#0078d4',
    fontWeight: 'bold',
}

const linkStyles: React.CSSProperties = {
    color: '#252525',
    textDecoration: 'none'
}

const basicListItemStyles: React.CSSProperties = {
    padding: '.8rem'
}

type AsideNavLink = {
    href: string,
    text: string
    childLinks?: AsideNavLink[]
}

const links: AsideNavLink[] = [
    {
        href: '#contacts',
        text: 'Contacts',
        childLinks: [
            {
                href: '#create-contact',
                text: 'Creating Contact'
            },
            {
                href: '#edit-contact',
                text: 'Edit Contact'
            }
        ]
    },
    {
        href: '#tool-managers',
        text: 'Tool Mangers',
        childLinks: [
            {
                href: '#add-tool-manager',
                text: 'Add Tool Manager'
            },
            {
                href: '#tool-manager-mapping',
                text: 'Create Mapping'
            }
        ]
    },
]

export const AsideNav = () => {
    return (
        <aside style={asideStyles}>
            <ul style={{ listStyleType: 'none' }}>
                {links.map((link: AsideNavLink) => {
                    return (
                        <li style={basicListItemStyles}>
                            <a href={link.href} style={{ ...linkStyles, ...(window.location.hash === link.href) ? activeLinkStyles : undefined }}>{link.text}</a>
                            {link.childLinks !== undefined &&
                                <ul style={basicListStyles}>
                                    {link.childLinks.map((childLink: AsideNavLink) => {
                                        return (
                                            <li style={basicListItemStyles}>
                                                <a href={childLink.href} style={{ ...linkStyles, ...(window.location.hash === childLink.href) ? activeLinkStyles : undefined }}>{childLink.text}</a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            }
                        </li>
                    )
                })}
            </ul>
        </aside>
    )
}