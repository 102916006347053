import { IPersonaProps, IBasePickerSuggestionsProps, IPersonaSharedProps } from '@fluentui/react';

export interface IPeoplePickerExampleState {
    delayResults: boolean;
    peopleList: IPersonaProps[];
    mostRecentlyUsed: IPersonaProps[];
    currentSelectedItems: IPersonaProps[];
}
export interface IPeoplePickerExampleProps {
    peopleList: IPersonaProps[];
    mostRecentlyUsed: IPersonaProps[];
    currentSelectedItems: IPersonaProps[];
}
export const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Suggested People',
    mostRecentlyUsedHeaderText: 'Suggested Contacts',
    noResultsFoundText: 'No results found',
    loadingText: 'Loading',
    showRemoveButtons: true,
    suggestionsAvailableAlertText: 'People Picker Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested contacts',
};

export type PersonListProps = {
    personList: PersonProps[];
};
export type PersonProps = {
    person?: IPersonaSharedProps;
    user?: string;
    alias?: string;
};
export type FindYourContactProps = {
    delayResults: boolean;
    contactList: PersonProps[];
    settingsOnclick: () => void;
} & PersonProps & IPeoplePickerExampleProps & any;
export interface IPeoplePickerExampleState {
    delayResults: boolean;
    peopleList: IPersonaProps[];
    mostRecentlyUsed: IPersonaProps[];
    currentSelectedItems: IPersonaProps[];
}
export interface IPeoplePickerExampleProps {
    peopleList: IPersonaProps[];
    mostRecentlyUsed: IPersonaProps[];
    currentSelectedItems: IPersonaProps[];
}