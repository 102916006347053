import React from "react";
import { ShadowProps } from "./Shadow";
export class Layout extends React.PureComponent<ShadowProps> {
    render() {
        const { children, title } = this.props;
        return (<div style={{ width: '100%', margin: "auto",padding:"1rem", display: 'inline-block', textAlign: 'center', marginTop: 50 }}>

            <h1 tabIndex={-1} style={{ margin: "auto", display: 'inline-block', marginBottom: 20, textAlign: 'center', width: '100%' }}>{title}</h1>
                <div>
                    {children}
                </div>

        </div>);
    }
}