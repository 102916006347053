export type Key = "Supporting Org Name" | "Work Area Name" | "Leaders Name" | "Leaders Organization Name" | "Sme Name" | "Communication Priority" | "Service Area Name" | "Last Updated By User Name" | "Last Updated Date Formatted";
export type KeyAndActions = Key | "Actions";
export const stringToKey = (str: KeyAndActions): string => {
    const splitKey: string[] = str.split(' ');
    splitKey[0] = splitKey[0].toLocaleLowerCase();
    return splitKey.join('');
};
export type IColumnType = {
    fieldName: KeyAndActions;
    key: string;
    name: string;
    minWidth?: number;
    maxWidthh?: number;
} & any;

export const contactGroupColumns: IColumnType[] = [
    {
        fieldName: "supportingOrgName",
        key: stringToKey("Supporting Org Name"),
        name: "Organization",
        isCollapsible: true,
        isResizable: true,
        minWidth: 75,
        maxWidth: 200,
    },
    {
        fieldName: "workAreaName",
        key: stringToKey("Work Area Name"),
        name: "Work Area",
        isCollapsible: true,
        isResizable: true,
        minWidth: 75,
        maxWidth: 100,
    },
    {
        fieldName: "leadsName",
        key: stringToKey("Leaders Name"),
        name: "Leader",
        isCollapsible: true,
        isResizable: true,
        minWidth: 75,
        maxWidth: 200,
    },
    {
        fieldName: "leadsOrganizationName",
        key: stringToKey("Leaders Organization Name"),
        name: "Leader Org",
        isCollapsible: true,
        isResizable: true,
        minWidth: 75,
        maxWidth: 200,
    },
    {
        fieldName: "smeName",
        key: stringToKey("Sme Name"),
        name: "Contact",
        isCollapsible: true,
        isResizable: true,
        minWidth: 75,
        maxWidth: 200,
    },
    {
        fieldName: "communicationPriority",
        key: stringToKey("Communication Priority"),
        name: "Contact Priority",
        isCollapsible: true,
        isResizable: true,
        minWidth: 75,
        maxWidth: 100,
    },
    {
        fieldName: "serviceAreaName",
        key: stringToKey("Service Area Name"),
        name: "Service Area",
        isCollapsible: true,
        isResizable: true,
        minWidth: 75,
        maxWidth: 100,
    },
    {
        fieldName: "lastUpdatedByUserName",
        key: stringToKey("Last Updated By User Name"),
        name: "Last Updated By",
        isCollapsible: true,
        isResizable: true,
        minWidth: 75,
        maxWidth: 200,
    },
    {
        fieldName: "lastUpdatedDateFormatted",
        key: stringToKey("Last Updated Date Formatted"),
        name: "Last Updated On",
        isCollapsible: true,
        isResizable: true,
        minWidth: 75,
        maxWidth: 200,
    },
    {
        fieldName: "Actions",
        key: stringToKey("Actions"),
        name: "Actions",
        isCollapsible: true,
        isResizable: true,
        maxWidth: 100,
    }
];