import React from "react";
import ReactDOM from "react-dom";
import { runWithAdal } from "react-adal";
import { initializeIcons } from "@fluentui/react";
import { FindContactContainer } from "./Components/FindContactContainer/FindContactContainer";
import { authContext } from "./Broker/endpoints";


initializeIcons();

runWithAdal(authContext, () => ReactDOM.render(<FindContactContainer />, document.getElementById("root")), false);
