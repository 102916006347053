import React from "react";
import { DefaultButton } from "@fluentui/react";
import { appInsights } from "../../AppInsights";
import { BulkUploadButton } from "../BulkImport/BulkUploadButton";
import { ShadowProps } from "../Shadow";
interface IMyContactGroupNavButtons{
    exporting:boolean
}
export class ContactGroupNavButtons extends React.PureComponent<ShadowProps,IMyContactGroupNavButtons>{
    constructor(props: any) {
        super(props);
        this.state = {
            exporting: false,
        }
    }

    render() {
        const { apiUploadContactGroups, alias, apiExportContacts } = this.props;
        return (
            <section style={{ margin: 20 }}>
                <DefaultButton
                    href={`/newcontactgroup`}
                    text="Add Contact"
                    iconProps={{ iconName: "add" }}
                    title="add new contact"
                    ariaLabel="add new contact"
                    onClick={() => appInsights.trackEvent({ name: 'Clicked on add new contact button' }, {
                        callingUser: alias, // who is logged in
                        callingAppId: process.env.REACT_APP_callingAppIdTelemetry
                    })}
                />
                <BulkUploadButton apiUploadContactGroups={apiUploadContactGroups} onClick={() => appInsights.trackEvent({ name: 'Clicked on import contacts' }, {
                    callingUser: alias, // who is logged in
                    callingAppId: process.env.REACT_APP_callingAppIdTelemetry
                })} apiExportContacts={this.props.apiExportContacts } />
                <DefaultButton
                    text="Export"
                    iconProps={{ iconName: (this.state.exporting) ? "ProgressRingDots" : "Download" }}
                    title="Export existing data as template"
                    ariaLabel="Export existing data as template"
                    disabled={this.state.exporting}
                    onClick={() => {
                        this.setState({ exporting: true });
                        appInsights.trackEvent({ name: 'Clicked on export contacts' }, {
                            callingUser: alias, // who is logged in
                            callingAppId: process.env.REACT_APP_callingAppIdTelemetry
                        })
                        apiExportContacts().finally(() => {
                            this.setState({ exporting: false });
                        });
                    }}
                />
            </section>
        )
    }
}