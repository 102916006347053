import * as React from 'react';
import {
    mergeStyleSets, MessageBar, MessageBarType,
} from '@fluentui/react';
import { ActionButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { TextField } from '@fluentui/react/lib/TextField';
import { useState } from 'react';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { AxiosError } from 'axios';

export const BulkUploadContent = ({ apiUploadContactGroups, onUploadComplete, apiExportContacts }: any) => {
    const [isUploading, setIsUploading] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [errorMsg, setErrorMsg] = useState("Error, please try again.")
    const resetErrorMsg = React.useCallback(() => setShowErrorMsg(false), []);
 
    return (
        <>
            <h2 id="modal-title">Import Contacts</h2>
            <div className={contentStyles.body}>
                <TextField label="Upload Excel Document" type="file" accept=".xlsx" id="excelFile" style={{ paddingTop: "4px" }} />
            </div>
            <div style={{ margin: "1em" }}>
                {isUploading ? <ProgressIndicator barHeight={10} label="Uploading" /> : null}
                <PrimaryButton text='Upload Document' style={{ width: "100%" }} onClick={upload} disabled={isUploading} />
            </div>
            <div style={{ textAlign: "right" }}>
                <ActionButton iconProps={{ iconName: (isExporting) ? "ProgressRingDots" : "DownloadDocument" }} onClick={exportContacts} title='Export existing data as template' disabled={isExporting}>
                    Export existing data as template
                </ActionButton>
            </div>
            {
                showErrorMsg ?
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        onDismiss={resetErrorMsg}
                        dismissButtonAriaLabel="Close Message"
                    >
                        {errorMsg}
                    </MessageBar>
                    : null
            }

        </>
    )
    function exportContacts() {
        setIsExporting(true)
        apiExportContacts().finally(() => {
            setIsExporting(false)
        });
    }

    function upload() {
        setIsUploading(true);
        const inputField = document.querySelector('#excelFile') as HTMLInputElement;
        if (!inputField || !inputField.files || inputField.files.length === 0) {
            setErrorMsg("No file selected");
            setShowErrorMsg(true);
            setIsUploading(false);
            return;
        }
        const file = inputField.files[0];
        const formData = new FormData();
        formData.append('file', file);
        apiUploadContactGroups(formData)
            .then((data: any) => {

                if (data === null || data === undefined) {
                    setShowErrorMsg(true);
                    return;
                }
                onUploadComplete();
            })
            .catch((error: AxiosError) => {
                if(!error.response){
                    setErrorMsg("Network error or no response from server");
                }else if (error.response.status === 400) {
                    setErrorMsg(error.response.data as string|| "Excel file is not in the correct format");
                }
                else if (error.response.status === 415) {
                    setErrorMsg("Not a valid Excel file, must be a .xlsx file.");
                }
                else if (error.response.status === 429) {
                    setErrorMsg("You already have an upload in progress.");
                } else {
                    setErrorMsg(`${error}`);
                }
                setShowErrorMsg(true);
            })
            .finally(() => setIsUploading(false));
    }
}


const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    body: {
        padding: '0 24px 24px 24px',
    },
});