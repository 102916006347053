import * as React from 'react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import {
  mergeStyleSets,
  Modal,
  IIconProps
} from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { BulkUploadContent } from './BulkUploadContent';

export const BulkUploadButton: React.FC<any>= ({ apiUploadContactGroups, onUploadComplete,apiExportContacts }: any) => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  // Use useId() to ensure that the IDs are unique on the page.
  // (It's also okay to use plain strings and manually ensure uniqueness.)
  const titleId = useId('title');

  return (
    <div style={{ display: "inline-block", margin: "0 1em" }}>
      <PrimaryButton iconProps={{ iconName: "upload" }} onClick={showModal} text="Import Contacts" />
          <Modal
        titleAriaId={"modal-title"}
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={false}
        containerClassName={contentStyles.container}
      >
        <BulkUploadContent apiUploadContactGroups={apiUploadContactGroups} onUploadComplete={hideModal} apiExportContacts={apiExportContacts} />
      </Modal>
    </div>
  );
};

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    padding: "1em"
  }
});