const fullWidthAutoInlineBlock = {
    width: "100%",
    margin: "auto",
    display: "inline-block",
    textAlign: 'center' as 'center',
};
export const titleStylesWithTopMargin = {
    ...fullWidthAutoInlineBlock,
    marginTop: 50
};
export const buttonStyles = {
    root: {
        width: "15%",
        height: 32,
        color: "white",
        padding: 0,
        margin: 5,
        marginTop: 20
    }
};
export const groupWithShadowsContainerStyles = {
    ...fullWidthAutoInlineBlock
};
export const groupWithShadowStyles = {
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    padding: 20,
    maxWidth: 300,
    width:"100%",
    margin: "auto",
    display: "inline-block",
};
export const titleStyles = {
    ...fullWidthAutoInlineBlock,
    marginBottom: 20,
};
export const buttoRootStyles = {
    width: "15%",
    height: 32,
    padding: 0,
    margin: 5,
    marginTop: 20
};
