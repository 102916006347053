import React from "react";
import { ContactGroup } from "./ContactGroup";
import { ActionButtons } from "../ActionButtons";
import { contactGroupColumns } from "./Columns";


export class ContactGroups extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        document.title = "Contacts";
        const { setState, apiContactGroups, isToolManager } = this.props;
        apiContactGroups().finally(()=>{
            setState({ contactsLoaded: true });
        });
        isToolManager()
            .then((res: any) => { 
                setState({ isToolManagerBool: res }); 
            });
    }
    render() {
        const { contactGroups, filterText, setState, contactGroupDetails, apiSearchUsers, apiSearchContactsLegal, currentSelectedItems } = this.props;
        return (
            <>
                <ContactGroup
                    {...this.props}
                    title={"Contacts"}
                    width={"95%"}
                    columns={contactGroupColumns}
                    items={this.items(contactGroups, filterText, setState, contactGroupDetails, apiSearchUsers, apiSearchContactsLegal, currentSelectedItems)}
                />
            </>
        );
    }

    private items(contactGroups: any, filterText: any, setState: any, contactGroupDetails: any, apiSearchUsers: any, apiSearchContactsLegal: any, currentSelectedItems: any): any {
        return contactGroups
            ? contactGroups
                .filter(this.FilterContractGroups(filterText))
                .map(this.contactGroupToRowItem(setState, contactGroupDetails, apiSearchUsers, apiSearchContactsLegal, currentSelectedItems))
            : [];
    }

    private contactGroupToRowItem(setState: any, contactGroupDetails: any, apiSearchUsers: any, apiSearchContactsLegal: any, currentSelectedItems: any): any {
        return (contactGroup: any, i: any) => {
            const { editMode } = this.props;
            const isInEditMode = editMode && editMode.assignmentId === contactGroup.assignmentId;
            return {
                ...contactGroup,
                Actions: [
                    <ActionButtons {...{ ...this.props }} editIcon={isInEditMode ? "save" : "edit"} editText={isInEditMode ? "Save" : "Edit"} viewIcon={isInEditMode ? "cancel" : "OpenEnrollment"} viewText={isInEditMode ? "Cancel" : "View/Edit"} key={i} m={contactGroup} isInEditMode />
                ]
            };
        };
    }

    private FilterContractGroups(filterText: any): any {
        return (contactGroup: any) => filterText
            ? JSON.stringify(contactGroup)
                .toLowerCase()
                .includes(filterText.toLowerCase())
            : true;
    }
}


