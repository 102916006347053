import * as React from 'react';
import { Dialog, DialogType, DialogFooter, DialogContent } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton, IconButton } from '@fluentui/react/lib/Button';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { useBoolean } from '@fluentui/react-hooks';
import { Spinner } from '@fluentui/react/lib/Spinner';

const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
};
const modalPropsStyles = { main: { maxWidth: 450 } };

interface DeleteDialogProps {
    toolmanager: any;
    post: any;
    setState: any;
    data: any;
    itemDeleted: any;
}
export const DeleteDialog: React.FC<DeleteDialogProps> = ({ toolmanager, post, setState, data, itemDeleted }) => {


//export const DeleteDialog: React.FunctionComponent = ({ data, toolmanager, post, setState }: any) => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [hideDialog2, { toggle: toggleHideDialog2 }] = useBoolean(true);
    const [loading, setLoading] = React.useState(false);

    const dialogContentProps = {
        type: DialogType.normal,
        title: `Delete ${toolmanager ? "Tool Manager" : "Contact"}`,
    };

    const dialogContentProps2 = {
        type: DialogType.normal,
        title: `${toolmanager ? "Tool Manager Deleted" : "Contact Deleted"}`,
    };

    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
        }), [],
    );

    const onDelete = () => {
        setLoading(true);
        if (!toolmanager) {
            post({
                id: data.assignmentId,
                partitionKey: data.assignmentPartitionKey,
                method: "DELETE",
                endpoint: "apiContactGroups"
            })
                .then(() => resetState())
                .finally(() => setLoading(false));
        }
        if (toolmanager) {
            if (data.workAreaId !== null) {
                post({
                    data: {
                        toolManagerId: data.toolManagerId,
                        tooLManagerPartitionKey: data.tooLManagerPartitionKey,
                        workAreaName: data.workAreaName,
                        workAreaId: data.workAreaId,
                        leadsAlias: data.leadsFullEmailAddress,
                        toolManagerAlias: data.toolManagerFullEmailAddress,
                    },
                    partitionKey: (data.tooLManagerPartitionKey !== "") ? data.tooLManagerPartitionKey : null,
                    method: "DELETE",
                    endpoint: "apiToolManagers"
                })
                    .then(() => resetState())
                    .finally(() => setLoading(false));
            } else {
                post({
                    id: data.toolManagerId,
                    partitionKey: (data.tooLManagerPartitionKey !== "") ? data.tooLManagerPartitionKey : null,
                    method: "DELETE",
                    endpoint: "apiToolDeleteManager"
                })
                    .then(() => resetState())
                    .finally(() => setLoading(false));
            }
        }
    }

    const resetState = () => {
        setLoading(false);
        toggleHideDialog();
        toggleHideDialog2();
        setState({
            itemDeleted: true,
        });
    }
    return (
        <>
            <IconButton
                styles={{ root: {} }}
                iconProps={{ iconName: "Delete" }}
                title="Delete"
                ariaLabel="Delete"
                onClick={toggleHideDialog} />
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >

                {!loading && <p>
                    Are you sure you want to delete this {toolmanager ? "Tool Manager" : "Contact"}?
                </p>}
                {loading && <Spinner label="Deleting..." />}

                <DialogFooter>
                    <PrimaryButton disabled={loading} onClick={onDelete} text="Delete" style={{ backgroundColor: "red", borderColor: "red" }} />
                    <DefaultButton disabled={loading} onClick={toggleHideDialog} text="Cancel" />
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={hideDialog2}
                onDismiss={toggleHideDialog2}
                dialogContentProps={dialogContentProps2}
                modalProps={modalProps}
            >
                {toolmanager ? "Tool manager deleted successfully" : "Contact deleted successfully"}
                <DialogFooter>
                    <DefaultButton onClick={toggleHideDialog2} text="Close" />
                </DialogFooter>
            </Dialog>
        </>
    );
};



